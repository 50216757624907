import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import "../components/all.scss"
import { MDXRenderer } from "gatsby-plugin-mdx"
import mediumZoom from 'medium-zoom'

export default class ArticlesTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.imgClickHandler = this.imgClickHandler.bind(this)
    this.state = {
      isActive: false,
      imgActiveClass: " "
    }
  }
  imgClickHandler(){
    this.setState({ isActive: !this.state.isActive })
    this.state.isActive ? this.setState({ imgActiveClass: "img-active" }) : this.setState({ navbarActiveClass: "" })
  }

  componentDidMount(){
    mediumZoom("img");
  }
  render() {
    const post = this.props.data.mdx;

    return (
      <Container>
        <div className={`blog-post`}>

          <section className={`hero is-medium is-primary is-bold`}>
            <div className={`hero-body`}>
              <div className={`container has-text-centered`}>
                <h1 className={`title`}>{post.frontmatter.title}</h1>
                <h2 className={`subtitle`}>{post.frontmatter.subtitle}</h2>
              </div>
            </div>
          </section>

          <section className={`blog-content`}>
            <div className={`columns is-centered is-mobile mt-5`}>
              <div className={`column is-10 is-10-mobile content`}>
                <MDXRenderer>{post.body}</MDXRenderer>
              </div>
            </div>

          </section>
        </div>
      </Container>
    )
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      
      frontmatter {
        slug
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
      }
      body
    }
  }
  `